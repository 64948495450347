<template>
    <div>
        <b-modal no-close-on-backdrop scrollable ok-title="Submit" title="Create SNMP Chart" class="modal-dark"
                 v-model="createModal">
            <label class="form-check-label">OID Select</label>
            <b-form-select :options="snmpOids" v-model="newSnmpGraph.oid">

            </b-form-select>
            <br>
            <br>
            <label class="form-check-label">Graph Type Select</label>
            <b-form-select v-model="newSnmpGraph.type">
                <option value="line">Line (value vs time)</option>
                <option value="bar">Bar (Number of occurences vs value)</option>
            </b-form-select>


            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="createModal=false">Cancel</b-button>
                <b-button :disabled="!newSnmpGraph.type || !newSnmpGraph.oid" size="sm" variant="primary"
                          @click="submitCreateModal">Submit
                </b-button>
            </template>
        </b-modal>

        <fa-icon :icon="['fas', 'square-plus']" @click="showCreateModal"></fa-icon>
        <div class="row">
            <div v-for="graph in snmpGraphs" :key="graph.oid" class="col-lg-6">
                <b-card v-if="render && graph.type==='line'" header="Line Chart">
                    <div class="chart-wrapper">
                        <line-example v-if="renderChart" :labels="graph.labels" :title="graph.title" :data="graph.data"
                                      chartId="chart-line-01"/>
                    </div>
                </b-card>

                <b-card v-if="render && graph.type==='bar'" header="Bar Chart">
                    <div class="chart-wrapper">
                        <bar-example v-if="renderChart" :labels="graph.labels" :title="graph.title" :data="graph.data"
                                     chartId="chart-line-01"/>
                    </div>
                </b-card>
            </div>

            <div class="col-lg-6">
                <b-card header="Device Up vs Down">
                    <div class="chart-wrapper">
                        <line-example v-if="renderChart" :labels="lineLabels" title="Camera Up vs Down" :data="lineData"
                                      chartId="chart-line-01"/>
                    </div>
                </b-card>
            </div>

            <div class="col-lg-6">
                <b-card header="Device Up vs Down">
                    <div class="chart-wrapper">
                        <pie-example v-if="renderChart" :labels="pieLabels" :data="pieData" chartId="chart-pie-02"/>
                    </div>
                </b-card>
            </div>

            <div class="col-lg-6">
                <b-card header="Device Response Time">
                    <div class="chart-wrapper">
                        <bar-example v-if="renderChart" :labels="barLabel" :avg="barDataAvg" :max="barDataMax"
                                     :min="barDataMin" chartId="chart-bar-03"/>
                    </div>
                </b-card>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import constellation from '../../services/constellation.js'
import BarExample from '../template_files/charts/BarExample'
import LineExample from '../template_files/charts/LineExample'
import DoughnutExample from '../template_files/charts/DoughnutExample'
import RadarExample from '../template_files/charts/RadarExample'
import PieExample from '../template_files/charts/PieExample'
import PolarAreaExample from '../template_files/charts/PolarAreaExample'
import constellationStore from "@/store/constellationStore";

export default {
    props: ["deviceId", "constellationId"],
    components: {
        BarExample,
        LineExample,
        DoughnutExample,
        RadarExample,
        PieExample,
        PolarAreaExample
    },
    data: () => {
        return {
            render: true,
            constellation: {},
            newSnmpGraph: {},
            createModal: false,
            snmpOids: [],
            snmpGraphs: [],
            users: [],
            snmpData: [],
            lineData: [],
            lineLabels: [],
            renderChart: false,
            pieLabels: [],
            pieData: [0, 0],
            yesterdayUnix: new Date().getTime() - (24 * 60 * 60 * 1000),
        }
    },
    created() {
        this.$emit('loading')
        constellationStore.dispatch('getConstellation', this.constellationId).then(response => {
            let url = response.url + ":" + response.port;
            this.constellation.url = response.url;
            this.constellation.port = response.port;
            //get snmp oids
            constellation.first(url, this.deviceId, this.yesterdayUnix).then(response => {
                response.data = response.data.map(a => a.oid)
                this.snmpOids = response.data.filter(this.onlyUnique);
            });

            this.snmpGraphs.forEach(graph => {
                constellation.second(url, this.deviceId, this.yesterdayUnix, graph.oid).then(response => {
                    graph.lineLabels = response.data.map(a => {
                        return this.makeDate(a.created_date);
                    });
                    graph.lineData = response.data.map(a => a.value)
                });
            });

            constellation.third(url, this.deviceId, this.yesterdayUnix).then(response => {
                for (let i = 0; i < response.data.length; i += 1) {
                    if (response.data[i].alive === "true") {
                        this.pieData[0]++;
                    } else {
                        this.pieData[1]++;
                    }
                }
                this.pieLabels = ["Up", "Down"]

                this.lineData = response.data.map(a => {
                    if (a.alive === "true") {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                this.lineLabels = response.data.map(a => {
                    return this.makeDate(a.created_date);
                });

                let temp = response.data.filter(data => data.time !== 'unknown');
                let barData = [];
                for (let i = 0; i < temp.length; i += 10) {
                    barData.push(temp[i]);
                }
                this.barLabel = barData.map(a => {
                    return this.makeDate(a.created_date);
                });

                this.barDataAvg = barData.map(a => {
                    return parseFloat(a.avg) - parseFloat(a.min);
                });
                this.barDataMax = barData.map(a => {
                    return parseFloat(a.max) - parseFloat(a.avg);
                });
                this.barDataMin = barData.map(a => {
                    return parseFloat(a.min);
                });
                this.$emit('loaded')
                this.renderChart = true;
            }).catch(error => {
                console.error(error)
            });
        });
    },

    methods: {
        onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        },
        showCreateModal() {
            this.newSnmpGraph = {};
            this.createModal = true;
        },
        submitCreateModal() {
            constellation.second(this.constellation.url + ":" + this.constellation.port, this.deviceId, this.yesterdayUnix, this.newSnmpGraph.oid).then(response => {
                this.render = false;
                if (this.newSnmpGraph.type === 'line') {
                    this.newSnmpGraph.labels = response.data.map(a => {
                        return this.makeDate(a.created_date);
                    });
                    this.newSnmpGraph.data = response.data.map(a => a.value)
                } else {
                    let labels = response.data.map(a => a.value);
                    this.newSnmpGraph.labels = labels.filter(this.onlyUnique);
                    this.newSnmpGraph.data = [];
                    for (let i = 0; i < this.newSnmpGraph.labels.length; i++) {
                        this.newSnmpGraph.data[i] = 0;
                        for (let nonUniqueValue of labels) {
                            if (this.newSnmpGraph.labels[i] === nonUniqueValue) {
                                this.newSnmpGraph.data[i]++;
                            }
                        }
                    }
                }

                this.newSnmpGraph.title = 'SNMP Graph' + this.newSnmpGraph.oid;

                this.snmpGraphs.push(this.newSnmpGraph);
                Vue.nextTick().then(() => {
                    this.render = true;
                });
                this.createModal = false;
            });
        },
        makeDate(time) {
            let created_date = time;
            let date = [];
            date.push(created_date.substring(0, 4));
            date.push(created_date.substring(5, 7));
            date.push(created_date.substring(8, 10));
            date.push(created_date.substring(11, 16));

            function tConvert(time) {
                time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
                if (time.length > 1) {
                    time = time.slice(1);
                    time[5] = +time[0] < 12 ? 'AM' : 'PM';
                    time[0] = +time[0] % 12 || 12;
                }
                return time.join('');
            }

            date[3] = tConvert(date[3]);
            return date[1] + "/" + date[2] + "/" + date[0] + " at " + date[3];
        },
    },
}
</script>

//1.3.6.1.2.1.2.2.1.5.1  current bandwith in bits/second
1.3.6.1.2.1.2.2.1.16.2 total numer of octets transmitted out of the interface
1.3.6.1.2.1.11.28.0 the total number of snmp get response pdus generated
1.3.6.1.2.1.5.8.0 number of ICMP echo messages recieved
1.3.6.1.2.1.6.4.0 the limit on the total number of TCP connection the entity can support
